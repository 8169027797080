body {
  height: 100%;
}



#nav {
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 100;
}

#help {
  position: fixed;
  top: 40px;
  right: 10px;
  z-index: 100;
}
