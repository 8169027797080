#listBox {
  width: 90%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 90vh;
  z-index: 100;
}

.image {
  max-width: 100%;
  max-height: 800px;
  object-fit: scale-down;
}

ul.imageList {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

li.imageList {
  height: 40vh;
  flex-grow: 1;
}

li.imageList:last-child {
  flex-grow: 10;
}

img.imageList {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
