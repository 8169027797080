#controls {
  position: fixed;
  bottom: 0;
  left: 5%;
  z-index: 100;
  /* font-style: italic; */
}

#switches {
  position: fixed;
  top: 10%;
  left: 5%;
  z-index: 100;
  /* font-style: italic; */
}

.select-label {
  margin-bottom: 5px;
}